define('kth/services/story-search', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        store: _ember['default'].inject.service('store'),

        init: function init() {
            this._super.apply(this, arguments);
        },

        searchWithKeyword: function searchWithKeyword(keyword) {
            var self = this;

            return new _ember['default'].RSVP.Promise(function (resolve) {
                var results = [];
                if (keyword !== '') {
                    var toxicStories = self.get('store').peekAll('toxic-story');
                    results = toxicStories.map(function (story) {

                        var title = story.get('title').toLowerCase();

                        var tags = (story.get('tags') || []).map(function (tag) {
                            return tag.label.toLowerCase();
                        }).join(', ');

                        var locations = (story.get('geo_tags') || {}).address.toLowerCase() || [];
                        var key = keyword.toLowerCase();

                        return title.indexOf(key) !== -1 || tags.indexOf(key) !== -1 || locations.indexOf(key) !== -1 ? story : null;
                    }).filter(function (story) {
                        return story;
                    });
                }
                resolve(results);
            });
        }
    });
});
define('kth/helpers/not-embedded-video', ['exports', 'ember'], function (exports, _ember) {
    exports.notEmbeddedVideo = notEmbeddedVideo;

    function notEmbeddedVideo(params) {
        if (params.length < 1) {
            return null;
        }
        var type = params[0];

        return !(type.indexOf('vimeo') !== -1 || type.indexOf('youtube') !== -1);
    }

    exports['default'] = _ember['default'].Helper.helper(notEmbeddedVideo);
});
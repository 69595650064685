define('kth/components/story-overlay', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        show: (function () {
            var disableClick = false;
            _ember['default'].$(".navbar-collapse ul li a[href^='#']").on('click', function (e) {
                // prevent default anchor click behavior
                e.preventDefault();
                if (!disableClick) {
                    var target = this.hash;
                    var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

                    // animate
                    var minHeight = _ember['default'].$('#my-navbar').height();
                    var navbarHeight = _ember['default'].$('.navbar').height() + 20;

                    if (viewportWidth < 768) {
                        _ember['default'].$('#my-navbar button.navbar-toggle').click();
                        navbarHeight = 0;
                    }

                    var scrollPosition = (minHeight > navbarHeight ? minHeight : navbarHeight) + 5;
                    var bodyScroll = _ember['default'].$('body').get(0).scrollTop;
                    var relativePosition = _ember['default'].$(target).offset().top + _ember['default'].$('.modal-body').get(0).scrollTop - scrollPosition - bodyScroll;

                    _ember['default'].$('#my-navbar .active').removeClass('active');
                    _ember['default'].$(this).closest('li').addClass('active');

                    disableClick = true;

                    _ember['default'].$('.modal-body').animate({
                        scrollTop: relativePosition
                    }, 500, 'swing', function () {
                        disableClick = false;
                    });
                }
            });

            /*
                scroll function for placeholder
            */
            _ember['default'].$(".story-paragraph a:not(.modalLink)[href^='#']").on('click', function (e) {
                // prevent default anchor click behavior
                e.preventDefault();
                if (!disableClick) {
                    var target = this.hash;

                    // animate
                    var navbarHeight = _ember['default'].$('.navbar').height() + 60;
                    var bodyScroll = _ember['default'].$('body').get(0).scrollTop;
                    var relativePosition = _ember['default'].$(target).offset().top + _ember['default'].$('.modal-body').get(0).scrollTop - navbarHeight - bodyScroll;

                    disableClick = true;
                    _ember['default'].$('.modal-body').animate({
                        scrollTop: relativePosition
                    }, 500, 'swing', function () {
                        disableClick = false;
                    });
                }
            });

            _ember['default'].$(".story-paragraph a.modalLink[href^='#']").on('click', function (e) {
                // prevent default anchor click behavior
                e.preventDefault();

                var target = _ember['default'].$(this.hash);
                if (target.hasClass('toxic-element-hidden')) {
                    target.removeClass('toxic-element-hidden');
                } else {
                    target.addClass('toxic-element-hidden');
                }
            });

            _ember['default'].$(document).ready(function () {
                _ember['default'].$('body').addClass('modal-open-no-overflow');
                //    let modal = Ember.$('.modal-body');

                //  Ember.$('.modal-body').scrollspy({target: "#myScrollspy"})

                /*       modal.scroll(function() {
                           let nav = Ember.$('#myScrollspy ul li');
                            let modalScrollTop = modal.scrollTop();
                            console.log('modalTop ' + modalScrollTop);
                           let toActivate = null;
                           nav.toArray().forEach(function(element) {
                               let obj = Ember.$(element.firstElementChild.getAttribute('href'));
                               let childPos = obj.offset();
                               let parentPos = obj.parent().offset();
                               let childOffset = childPos.top - parentPos.top;
                                console.log('el: ' + childOffset);
                                if (modalScrollTop > childOffset && !toActivate) {
                                   toActivate = element;
                                }
                           }, this);
                           Ember.$('#my-navbar .active').removeClass('active');
                           Ember.$(toActivate).addClass('active');
                        });*/
            });

            /*
                     Ember.$(document).ready(function(){
            		   // cache the window object
            		   let $modal = $('.modal-body');
            
            		        Ember.$('.full-panel.parallax').each(function() {
            		            // declare the variable to affect the defined data-type
            		            var $scroll = Ember.$(this);
            
            		            Ember.$('.modal-body').scroll(function() {
                                    let yPos = -129;
            		                // HTML5 proves useful for helping with creating JS functions!
            		                // also, negative value because we're scrolling upwards
                                    let modalScrollTop = $modal.scrollTop();
                                    let elementPositionTop = $scroll.position().top;
                                    console.log('modalTop ' + modalScrollTop);
                                    console.log('elTop ' + elementPositionTop);
                                    if (modalScrollTop > elementPositionTop) {
                                        let margin = modalScrollTop * 1.05;
                                        yPos = (modalScrollTop) - margin;
                                        console.log(yPos);
            
                                        // background position
                                        var coords = '50% ' + yPos + 'px';
            
                                        // move the background
                                        $scroll.css({ backgroundPosition: coords });
                                    }
            		            }); // end window scroll
            		        });  // end section function
            
            		}); // close out script
            */

            this.$('.modal').modal().on('hidden.bs.modal', (function () {
                this.sendAction('close');
            }).bind(this));
        }).on('didInsertElement')
    });
});
define('kth/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].JSONSerializer.extend({
        normalizePayload: function normalizePayload(type, payload) {
            return { category: payload };
        },
        normalize: function normalize(type, hash, prop) {
            console.log(prop);
            return prop;
            // change your incoming JSON to ember-style here
        },
        serialize: function serialize(record) {
            console.log(record);
            return record;
            // change your outgoing ember-data record to your server's JSON here
        }
    });
});
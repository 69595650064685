define('kth/routes/about', ['exports', 'ember', 'kth/config/environment', 'rsvp'], function (exports, _ember, _kthConfigEnvironment, _rsvp) {
    exports['default'] = _ember['default'].Route.extend({
        pageAbout: null,

        actions: {
            didTransition: function didTransition() {
                this._super();

                _ember['default'].$('.search-bar').css('visibility', 'hidden');
                return true;
            }
        },

        model: function model() {
            var store = this.store;
            var self = this;

            if (self.pageAbout) {
                return self.pageAbout;
            }

            if (_kthConfigEnvironment['default'].apiUrl && _kthConfigEnvironment['default'].apiUrl !== '') {
                var _ret = (function () {
                    var apiBaseUrl = _kthConfigEnvironment['default'].apiUrl;
                    var promises = [];

                    return {
                        v: new _ember['default'].RSVP.Promise(function (resolve) {
                            _ember['default'].$.getJSON(apiBaseUrl + '/objects/about?embed[relations]=partner|49,team|49').done(function (response) {
                                var object = response.data.object || null;
                                if (object) {
                                    var _ret2 = (function () {
                                        object.relations.team && object.relations.team.objects && object.relations.team.objects.forEach(function (team) {
                                            if (team.relations.poster && team.relations.poster.count) {
                                                var getPoster = _ember['default'].$.getJSON(team.relations.poster.url, function (response) {
                                                    var cover = response.data.objects.length && response.data.objects[0].uri;
                                                    team.cover = cover;
                                                });
                                                promises.push(getPoster);
                                            }
                                            if (team.relations.link && team.relations.link.count) {
                                                var getLinks = _ember['default'].$.getJSON(team.relations.link.url, function (response) {
                                                    team.relations.link.objects = response.data.objects.length && response.data.objects || [];
                                                });
                                                promises.push(getLinks);
                                            }
                                        }, self);

                                        object.relations.partner && object.relations.partner.objects && object.relations.partner.objects.forEach(function (partner) {
                                            if (partner.relations.poster && partner.relations.poster.count) {
                                                var getPoster = _ember['default'].$.getJSON(partner.relations.poster.url, function (response) {
                                                    var cover = response.data.objects.length && response.data.objects[0].uri;
                                                    partner.cover = cover;
                                                });
                                                promises.push(getPoster);
                                            }

                                            if (partner.relations.link && partner.relations.link.count) {
                                                var getLinks = _ember['default'].$.getJSON(partner.relations.link.url, function (response) {
                                                    partner.link = response.data.objects.length && response.data.objects[0] || [];
                                                });
                                                promises.push(getLinks);
                                            }
                                        }, self);

                                        var objModel = store.createRecord('document', object);
                                        _ember['default'].set(self, 'pageAbout', objModel);

                                        return {
                                            v: _rsvp['default'].all(promises).then(function () {
                                                return resolve(objModel);
                                            })
                                        };
                                    })();

                                    if (typeof _ret2 === 'object') return _ret2.v;
                                }
                            });
                        })
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            }
        }
    });
});
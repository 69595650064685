define('kth/controllers/media', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        customPlayerVars: {
            autoplay: 1,
            height: '500',
            width: '720'
        },

        youtube: {
            english: 'ZNkw-CQuV80',
            italian: 'UMlYORsR3-U',
            swedish: 'AQ30I_K-G6c',
            spanish: 'WVOiPyGxuHo',
            catalan: '8deJk7GLMqA',
            french: 'Rz4DTVyrRI8',
            portuguese: '7Bw5aTEzLss'
        },

        videoSource: 'ZNkw-CQuV80',
        videoTitle: 'English',

        actions: {
            changeVideoSource: function changeVideoSource(language) {
                this.set('videoSource', this.youtube[language]);
                var capitalizedLanguage = language.charAt(0).toUpperCase() + language.slice(1);
                this.set('videoTitle', capitalizedLanguage);

                _ember['default'].$('.language-select .active').removeClass('active');
                _ember['default'].$('button[name=' + language + ']').addClass('active');
            }
        }
    });
});
define('kth/mixins/scrolling', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        bindScrolling: function bindScrolling() {
            var onScroll,
                _this = this;

            onScroll = function () {
                return _this.scrolled();
            };

            _ember['default'].$(document).bind('touchmove', onScroll);
            _ember['default'].$(window).bind('scroll', onScroll);
        },

        unbindScrolling: function unbindScrolling() {
            _ember['default'].$(window).unbind('scroll');
            _ember['default'].$(document).unbind('touchmove');
        }
    });
});
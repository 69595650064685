define('kth/controllers/stories', ['exports', 'ember'], function (exports, _ember) {

    // const defaultMap = 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
    // const greyMap = 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png';
    var waterColor = 'http://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png';
    /* const maxBound = L.latLngBounds(L.latLng(85, -175), L.latLng(-84, 160)); */
    var maxBound = L.latLngBounds(L.latLng(82, -175), L.latLng(-84, 160));

    var markerIcon = L.icon({
        iconUrl: 'assets/images/icons/markerIcon.png',

        iconSize: [38, 55],
        iconAnchor: [19, 54], // point of the icon which will correspond to marker's location
        shadowUrl: 'assets/images/icons/markerShadow.png',
        shadowSize: [61, 34], // size of the shadow
        shadowAnchor: [2, 33] });

    // the same for the shadow
    var cssIcon = L.divIcon({
        className: 'cssMarker',
        html: '<div class=\'pin\'></div><div class=\'shadow\'></div>'
    });

    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            openModal: function openModal(name, model, layout) {
                this.send('showModal', name, model, layout);
            },

            openTooltip: function openTooltip(model) {
                var tooltip = _ember['default'].$('#tooltip-' + model.id);
                tooltip.addClass('show fade in');
            },

            closeTooltip: function closeTooltip(model) {
                var tooltip = _ember['default'].$('#tooltip-' + model.id);
                tooltip.removeClass('show  in');
            },

            addAttributes: function addAttributes(id, event) {
                var markerElement = event.target._icon;
                markerElement.setAttribute('id', 'marker-' + id);
            }
        },

        layout: {
            left: 2,
            center: 8,
            right: 2
        },

        map: {
            lat: 30.85,
            lng: 0,
            zoom: 2,
            maxZoom: 16,
            minZoom: 2,
            keyboard: true,
            noWrap: true,
            maxBounds: maxBound,
            tile: waterColor,
            markerIcon: markerIcon,
            cssIcon: cssIcon,
            zoomDelta: 1
        },

        showLoader: true,

        infoBanner: null,

        toxicStories: []

    });
});
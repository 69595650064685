define('kth/helpers/get-vimeo-url', ['exports', 'ember'], function (exports, _ember) {
    exports.notEmbeddedVideo = notEmbeddedVideo;

    function notEmbeddedVideo(params) {
        if (params.length < 1) {
            return null;
        }
        var value = params[0];
        var urlArray = value.split('/');
        var vimeoId = urlArray[urlArray.length - 1];

        return 'http://player.vimeo.com/video/' + vimeoId + '?&byline=0&portrait=0';
    }

    exports['default'] = _ember['default'].Helper.helper(notEmbeddedVideo);
});
define('kth/routes/stories', ['exports', 'ember', 'kth/config/environment', 'rsvp'], function (exports, _ember, _kthConfigEnvironment, _rsvp) {

    var maxBound = L.latLngBounds(L.latLng(82, -175), L.latLng(-84, 160));
    var flyToZoom = 9;

    exports['default'] = _ember['default'].Route.extend({
        toxicStories: [],
        map: null,

        actions: {
            didTransition: function didTransition() {
                this._super();

                _ember['default'].$('.search-bar').css('visibility', 'visible');
                return false;
            },

            getMap: function getMap(map) {
                this.set('map', map.target);
            },

            showModal: function showModal(name, model, layout) {

                this.render(name, {
                    into: 'application',
                    outlet: 'modal',
                    model: model,
                    layout: layout
                });
            },
            removeModal: function removeModal() {
                var self = this;
                self.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
                console.log('vvv');
                self.render({
                    model: self.model()
                });
            },

            searchFilter: function searchFilter(filter) {
                var visibleMarkers = [];
                if (this.toxicStories.length) {
                    this.toxicStories.map(function (story) {
                        var storyId = story['id'];
                        if (filter.length && !filter.includes(storyId)) {
                            _ember['default'].$('#marker-' + storyId).addClass('hidden');
                        } else {
                            _ember['default'].$('#marker-' + storyId).removeClass('hidden');
                            visibleMarkers.push('marker-' + storyId);
                        }
                    });

                    var markers = _ember['default'].$('.cssMarker');
                    markers.each(function (index, marker) {
                        var pulse = _ember['default'].$(marker).find('.shadow');
                        pulse.removeClass('pulse');
                        setTimeout(function () {
                            pulse.addClass('pulse');
                        }, 50);
                    });

                    // zoom map on markers
                    this.mapSetBounds(visibleMarkers);
                }
            },

            mapFlyTo: function mapFlyTo(marker) {

                this.map.flyTo(marker.location, flyToZoom, {
                    easeLinearity: 0.75
                });
                /*             let self = this;
                            this.map.on('zoomend', function() {
                                self.send('searchFilter', []);
                            }); */
            }
        },

        model: function model() {
            var _this = this;

            var store = this.store;
            var that = this;
            var self = this;

            if (this.toxicStories.length) {
                return this.toxicStories;
            }

            var promises = [];

            if (_kthConfigEnvironment['default'].apiUrl && _kthConfigEnvironment['default'].apiUrl !== '') {
                var apiBaseUrl = _kthConfigEnvironment['default'].apiUrl;

                _ember['default'].$.getJSON(apiBaseUrl + '/objects/info-banner').done(function (resp) {
                    var desc = resp.data.object.body;
                    self.controller.set('infoBanner', desc);
                }).fail(function (jqxhr, textStatus) {
                    console.log(textStatus);
                });

                var pageSize = 6;
                var totalPages = 10;

                var _loop = function (page) {
                    promises.push(_ember['default'].$.getJSON(apiBaseUrl + ('/objects/toxic-stories/contents?filter[object_type]=toxic_story&embed[relations]=poster&page_size=' + pageSize + '&page=' + page)).done(function (response) {
                        var objs = response && response.data.objects || [];
                        totalPages = response && response.paging && response.paging.total_pages || page;

                        objs.forEach(function (obj) {
                            if (obj.geo_tags.length) {
                                obj.geo_tags = obj.geo_tags[0];
                                obj.geoTag = [obj.geo_tags.latitude, obj.geo_tags.longitude];
                            }

                            if (obj.relations && obj.relations.contains) {
                                promises.push(_ember['default'].$.getJSON(obj.relations.contains.url, function (response) {
                                    obj.relations.contains.objects = response.data.objects;

                                    obj.relations.contains.objects.forEach(function (document) {
                                        if (document.relations) {
                                            document.relations.attach = self.getAttach(document);
                                            document.relations.poster = self.getPoster(document);
                                        }
                                    });
                                    var objModel = undefined;
                                    if (!store.hasRecordForId('toxic-story', obj['id'])) {
                                        objModel = store.createRecord('toxic-story', obj);
                                    } else {
                                        objModel = store.peekRecord('toxic-story', obj['id']);
                                    }
                                    that.toxicStories.pushObject(objModel);
                                }));
                            }
                        });
                    }).fail(function () {
                        that.afterLoad();
                    }));
                };

                for (var page = 1; page <= totalPages; page++) {
                    _loop(page);
                }

                _rsvp['default'].all(promises).then(function () {
                    var controller = _this.controller;
                    controller.set('toxicStories', _this.toxicStories);

                    that.afterLoad();
                });

                return this.toxicStories;
            }
        },

        mapSetBounds: function mapSetBounds(ids) {
            var arrayOfMarkers = [];
            var searchedMarkersGroup = undefined;

            this.map.eachLayer(function (layer) {
                if (layer._icon) {
                    var markerId = layer._icon.getAttribute('id');
                    console.log(ids, markerId);
                    if (ids.contains(markerId)) {
                        arrayOfMarkers.push(layer);
                    }
                }
            });

            console.log(arrayOfMarkers);
            if (arrayOfMarkers.length) {
                searchedMarkersGroup = L.featureGroup(arrayOfMarkers).addTo(this.map);
                var newBounds = searchedMarkersGroup.getBounds();

                this.map.flyToBounds(newBounds, {
                    maxZoom: flyToZoom
                });
            } else {
                this.map.fitBounds(maxBounds);
            }
        },

        getAttach: function getAttach(document) {
            if (!document) {
                return [];
            }
            var attach = document.relations && document.relations.attach || {};
            var url = attach.url;

            if (url) {
                _ember['default'].$.getJSON(url, function (response) {
                    attach.objects = response.data.objects;
                });
            }

            return attach;
        },

        getPoster: function getPoster(document) {
            if (!document) {
                return [];
            }
            var poster = document.relations && document.relations.poster || {};
            var url = poster.url;

            if (url) {
                _ember['default'].$.getJSON(url, function (response) {
                    poster.objects = response.data.objects;
                });
            }

            return poster;
        },

        /*     loadStories() {
                return toxicStories;
            },
         */
        beforeLoad: function beforeLoad() {
            var controller = this.controller;
            controller.set('showLoader', true);
        },

        afterLoad: function afterLoad() {
            var controller = this.controller;
            controller.set('showLoader', false);
        }
    });
});
define('kth/helpers/format-chapter', ['exports', 'ember'], function (exports, _ember) {
    exports.formatChapter = formatChapter;

    function formatChapter(params) {
        if (params.length < 1) {
            return null;
        }
        var value = params[0] + (params[1] || 1);
        return (value < 10 ? '0' : '').concat(value.toString());
    }

    exports['default'] = _ember['default'].Helper.helper(formatChapter);
});
define('kth/components/toxic-video', ['exports', 'ember', 'kth/config/environment'], function (exports, _ember, _kthConfigEnvironment) {

  var ToxicVideo = _ember['default'].Component.extend({
    video: _ember['default'].computed('params.[]', function () {
      return this.get('params')[0];
    }),
    title: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 1 ? this.get('params')[1] : this.get('video') && this.get('video').title || '';
    }),
    layout: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 2 ? this.get('params')[2] : _kthConfigEnvironment['default'].DEFAULT_BOOTSTRAP_LAYOUT;
    })
  });

  ToxicVideo.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = ToxicVideo;
});
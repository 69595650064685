define('kth/models/document', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    exports['default'] = _emberData['default'].Model.extend({
        title: attr('string'),
        description: attr('text'),
        status: attr('string'),
        nickname: attr('string'),

        created: attr('date'),
        modified: attr('date'),
        body: attr('text')
    });
});
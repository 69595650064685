define('kth/components/search-bar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        globalSearch: _ember['default'].inject.service('story-search'),
        searchResults: [],

        init: function init() {
            this._super.apply(this, arguments);
            this.searchResults = [];
        },

        focusOut: function focusOut() {
            var self = this;
            setTimeout(function () {
                self.$().find('ul').removeClass('active');
            }, 150);
        },

        focusIn: function focusIn() {
            this.$().find('ul').addClass('active');
        },

        clearSearchInput: function clearSearchInput() {
            this.set('keyword', '');
            this.send('search');
        },

        actions: {
            navigate: function navigate(value, evt) {
                var computedEvent = typeof event !== 'undefined' ? event : evt;
                var domElement = this.$();
                var input = domElement.find('input');
                var results = domElement.find('ul li');

                var activeEl = domElement.find('.keypress-selected');

                if (results.length && typeof computedEvent !== 'undefined') {
                    if (computedEvent.which === 40) {
                        // arrow down
                        computedEvent.preventDefault();
                        if (activeEl.is('input') || activeEl.length === 0) {
                            activeEl.removeClass('keypress-selected');
                            results.first().addClass('keypress-selected');
                            console.log(results.first());
                        } else {
                            activeEl.removeClass('keypress-selected');
                            var nextMenuEntry = activeEl.next('li');
                            if (nextMenuEntry.length) {
                                nextMenuEntry.addClass('keypress-selected');
                            } else {
                                input.addClass('keypress-selected');
                            }
                        }
                    } else if (computedEvent.which === 38) {
                        // arrow up
                        computedEvent.preventDefault();
                        if (activeEl.is('input') || activeEl.length === 0) {
                            activeEl.removeClass('keypress-selected');
                            results.last().addClass('keypress-selected');
                        } else {
                            activeEl.removeClass('keypress-selected');
                            var prevMenuEntry = activeEl.prev('li');
                            if (prevMenuEntry.length) {
                                prevMenuEntry.addClass('keypress-selected');
                            } else {
                                input.addClass('keypress-selected');
                            }
                        }
                    } else if (computedEvent.which === 13) {
                        computedEvent.preventDefault();
                        if (activeEl.is('input')) {
                            activeEl.blur();

                            if (results.length === 1) {
                                results.first().click();
                            }
                        } else {
                            input.blur();
                            activeEl.click();
                        }
                    }
                }
            },

            search: function search(value, evt) {
                var _this = this;

                var computedEvent = typeof event !== 'undefined' ? event : evt;

                var _getProperties = this.getProperties('keyword');

                var keyword = _getProperties.keyword;

                // hack to avoid rerender in template
                if (typeof computedEvent !== 'undefined' && (computedEvent.which === 38 || computedEvent.which === 40)) {
                    return;
                }
                this.get('globalSearch').searchWithKeyword(keyword).then(function (result) {
                    var searchResults = result.map(function (entry) {
                        return {
                            id: entry.get('id'),
                            title: entry.get('title'),
                            location: entry.get('geoTag')
                        };
                    });

                    _this.set('searchResults', searchResults);

                    var filteredId = result.map(function (entry) {
                        return entry.get('id');
                    });
                    _this.sendAction('sendDataToParent', filteredId);
                }, function (err) {
                    console.error('Error while searching: ' + err.responseText);
                });
            },

            clearSearch: function clearSearch() {
                this.clearSearchInput();
            },

            panToMarker: function panToMarker(location, id) {
                //    this.clearSearchInput();
                this.sendAction('mapFlyTo', {
                    id: id,
                    location: location
                });
            }
        }
    });
});
define('kth/services/modal-dialog', ['exports', 'ember', 'kth/config/environment'], function (exports, _ember, _kthConfigEnvironment) {
  var computed = _ember['default'].computed;
  var Service = _ember['default'].Service;

  function computedFromConfig(prop) {
    return computed(function () {
      return _kthConfigEnvironment['default']['ember-modal-dialog'] && _kthConfigEnvironment['default']['ember-modal-dialog'][prop];
    });
  }

  exports['default'] = Service.extend({
    hasEmberTether: computedFromConfig('hasEmberTether'),
    hasLiquidWormhole: computedFromConfig('hasLiquidWormhole'),
    hasLiquidTether: computedFromConfig('hasLiquidTether'),
    destinationElementId: computed(function () {
      /*
        everywhere except test, this property will be overwritten
        by the initializer that appends the modal container div
        to the DOM. because initializers don't run in unit/integration
        tests, this is a nice fallback.
      */
      if (_kthConfigEnvironment['default'].environment === 'test') {
        return 'ember-testing';
      }
    })
  });
});
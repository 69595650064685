define('kth/components/scroll-header', ['exports', 'ember', 'kth/mixins/scrolling'], function (exports, _ember, _kthMixinsScrolling) {
    exports['default'] = _ember['default'].Component.extend(_kthMixinsScrolling['default'], {
        didInsertElement: function didInsertElement() {
            // this.bindScrolling();

            function getShrinkingOffset() {
                var windowSize = _ember['default'].$(window).width();
                if (windowSize > 1300) {
                    return 110;
                } else if (windowSize > 1200) {
                    return 80;
                } else if (windowSize > 992) {
                    return 50;
                } else {
                    return 40;
                }
            }

            _ember['default'].$(window).bind('scroll', function () {
                var shrinkOn = getShrinkingOffset();

                var header = _ember['default'].$('header');
                var headerContainer = _ember['default'].$('.header-container');
                var distanceY = window.pageYOffset || document.documentElement.scrollTop;
                if (distanceY > shrinkOn) {
                    header.addClass("smaller");
                    headerContainer.addClass('smaller');
                } else {
                    if (header.hasClass("smaller")) {
                        header.removeClass("smaller");
                    }
                    if (headerContainer.hasClass("smaller")) {
                        headerContainer.removeClass("smaller");
                    }
                }
            });
        },

        actions: {
            sendDataToRoute: function sendDataToRoute(data) {
                this.sendAction('lastAction', data);
            },

            mapFlyTo: function mapFlyTo(location) {
                this.sendAction('mapFlyTo', location);
            }
        }
    });
});
define('kth/components/toxic-audio', ['exports', 'ember', 'kth/config/environment'], function (exports, _ember, _kthConfigEnvironment) {

  var ToxicAudio = _ember['default'].Component.extend({
    audio: _ember['default'].computed('params.[]', function () {
      return this.get('params')[0];
    }),
    title: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 1 ? this.get('params')[1] : this.get('audio') && this.get('audio').title || '';
    }),
    layout: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 2 ? this.get('params')[2] : _kthConfigEnvironment['default'].DEFAULT_BOOTSTRAP_LAYOUT;
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var toxicAudio = _ember['default'].$(this.element).find('.toxic-audio');
      var ref = toxicAudio.attr('id');
      var paragraph = toxicAudio.closest('.story-paragraph');
      var placerefContainer = paragraph.find('.placeref-container');
      var anchor = paragraph.find('a[href=' + ref + ']');
      var refType = anchor.attr('class');

      //let element = this.element;

      anchor.attr('href', '#' + ref);
      switch (refType) {
        case 'placeholder':
          anchor.after(toxicAudio);
          break;
        case 'placeref':
          placerefContainer.append(toxicAudio);
          break;
        case 'modalLink':
          toxicAudio.addClass('toxic-element-hidden');
          anchor.after(toxicAudio);
          break;
      }
    }
  });

  ToxicAudio.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = ToxicAudio;
});
define('kth/helpers/get-youtube-id', ['exports', 'ember'], function (exports, _ember) {
    exports.getYoutubeId = getYoutubeId;

    function getYoutubeId(params) {
        if (params.length < 1) {
            return null;
        }
        var value = params[0];

        var video_id = value.split('v=')[1];
        var ampersandPosition = video_id.indexOf('&');
        if (ampersandPosition !== -1) {
            video_id = video_id.substring(0, ampersandPosition);
        }
        return video_id;
    }

    exports['default'] = _ember['default'].Helper.helper(getYoutubeId);
});
define('kth/locales/en/translations', ['exports'], function (exports) {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"

    exports['default'] = {
        'language-select': {
            'language': {
                'it': 'ita',
                'en': 'eng'
            }
        },
        'guidelines': {
            'main': {
                'title': '<h1>Guerrilla</h1><h1>narrative</h1>'
            },
            'intro': {
                'left': 'We have tried to put together a short instruction manual for those who would like to participate to our guerrilla narrative project. ' + 'The manual is divided into 4 chapters, one for each of the ways you call tell your story. We added a short chapter where we propose you to free your ' + 'imagination and submit your story in different ways.',
                'right': 'Enjoy the guerrilla narrative. And please, do remember to shake yourself well before using.'
            },
            'chapter1': {
                'title': '<p>CHAPTER I</p><p>HOW TO WRITE A TOXIC</p><p>AUTOBIOGRAPHY</p>',
                'page': {
                    '1': {
                        'left': {
                            '1': '<p><br>In the USA, there is a growing field of “toxic autobiographies”. The genre is produced by people who have encountered contamination, ' + 'in one way or another, during their lives.</p>',
                            '2': '<p>In Europe, such genre is almost absent or at least invisible, because most of these texts have not been distributed widely (they are often selfproduced ' + 'or printed by small publishing houses). The aim of our project is to collect already existing materials and also encourage the production of ' + '“toxic autobiographies”.</p>'
                        },
                        'right': '<p> Hereunder, we provide few suggestions on how to write your text. </p> <p> - You can start describing yourself from your name, age, country of origin, level of education and profession. Describe your relationship with the place you live in, starting from your childhood,     of occupational health. You can also tell the story of the everyday relationship with contamination (stench, pollution, waste, your community getting sick, the devastated landscape). </p>'

                    },
                    '2': {
                        'left': '<p> <br> - Tell the story of how you gained awareness. Often we are contaminated/affected by things that we don’t see. Even when we do see them, we might not realise the danger that comes along with them. How did you start becoming aware of the contamination? What made you     we would like to encourage you to tell those painful stories. We believe it is important that such stories do not get lost. Instead, such stories should become a socialised shared memory. </p>',
                        'right': '<p> - Tell the story of the protest against contamination. Again, you don’t need to dwell on all the dates, groups, but instead try to keep telling your own story. Did you participate in any mobilisation? Which and When? What did you do? If you did not participate, please explain why. What did mobilising meant in your life? Have you found new friends / passion for politics? Was it a disappointing experience? Did you end up creating enemies? Could you list the positive sides and maybe also the negative ones that you see in these struggles/movements? </p> <p> - Try to keep a personal and autobiographical style, in telling the story of your neighbourhood/city/community. Remember to recall your experiences and how did you feel in relation to events and moments. </p> <p> - Do not worry about the style nor the precision of language. Toxic Bio is a guerrilla narrative experiment, we don’t care about rules and formalities, which are typical of a traditional written text. </p>'
                    }
                }

            },
            'chapter2': {
                'title': '<p>CHAPTER II</p><p> HOW TO TELL YOUR TOXIC </p> <p> AUTOBIOGRAPHY THROUGH A VIDEO </p>',
                'page': {
                    '1': {
                        'left': '<p>Another way to tell your story may be to shoot a simple video. Today we are all well-equipped with quality cameras on our cell phones. Maybe we are not capable of shooting an award-winning video, but we can easily record our story. Here are some technical tips, for the contents we refer to Chapter I</p><br><br><br><br><br><br><br><br><br><br><br><br><p> - If you don’t feel comfortable with technology, contact us at: ilenia@kth.se, and we can arrange a shooting with a person which collaborates with the project. - Audio: test the quality of the audio. Before you start shooting the whole video make sure to record few seconds. Watch the video and make sure it all works fine. </p>',
                        'right': '<p> - If there is background noise, such as an alarm or a dog, do not think about deleting the video and start from scratch again. Just pause your talk until the disturbance is over, then restart from where you left. - Try to talk at a slow pace, it will be easier to work on subtitles. - Make sure there is enough light but try to avoid direct light on the camera. </p> <p> </p> <p> How to send the video file - If the file is smaller than 25 MB (unlikely in the case of a video), you can send it to : ilenia@kth.se - Don’t worry, instead, if the file is bigger than 25 MB. You can use the following website for sharing bigger files: https://wetransfer.com/. This website allows you to add a file from your computer clicking on the blue “+” icon. After that, you need to insert the email you would like to send the files to (ilenia@kth.se) and the email you are sending the files from (your own one). In the message box, you should write your full name. Last but not least (we are almost done!), click on the “transfer” button and the file will be sent to us. Thank you for following the steps all the way through! </p>'
                    }
                }
            },
            'chapter3': {
                'title': '<p> CHAPTER III </p> <p> HOW TO TELL YOUR TOXIC </p> <p> AUTOBIOGRAPHY THROUGH AN AUDIO </p>',
                'page': {
                    '1': {
                        'left': '<p> Recording an audio file may be an easier option, which avoids the complications of a video. Also for recording an audio, all you need to have is your cell phone, all have a recorder function. Otherwise, you can use a digital recorder or a program that you can use from your computer or other devices, like Audacity. For the contents we refer to Chapter I. </p>',
                        'right': '<p> - If you prefer, we can arrange a meeting with a person that collaborates with the project or we can set a Skype call. Contact us at: ilenia@kth.se - Remember to talk slowly. - Adjust your distance from the microphone. - First, make a test. Register few seconds of the audio file and then listen to it. Avoid the frustration of recording all in one shot and then, at the very end, realise that the sound did not work. - If there is any background noise or disturbance during the recording, do not think about deleting the video and start from scratch again. Just pause your talk until the disturbance is over, then restart from where you left. - Please, avoid to register in an environment with continuous background noise, such as a tv on, traffic etc. </p> <p> </p> <p> How to send the audio file - If the file is smaller than 25 MB (likely in the case of an audio), you can send it to: ilenia@kth.se - Don’t worry, instead, if the file is bigger than 25 MB. You can use the following website for sharing bigger files: https://wetransfer.com/. For details, go back to Chapter II. </p>'
                    }
                }
            },
            'chapter4': {
                'title': '<p> CHAPTER IV </p> <p> HOW TO TELL YOUR TOXIC </p> <p> AUTOBIOGRAPHY THROUGH PICTURES </p>',
                'page': {
                    '1': {
                        'left': '<p> In our digital times, it is easy to produce and accumulate photos. If you prefer, you can tell your story through them. Please, choose at least 5 photos. You can use both “archival” pictures, such as pictures that you keep about yourself, you family or neighbourhood, struggles or contamination. Otherwise, you can also decide to take new pictures to write your story. Write a caption for each photo you have chosen.</p><br><br><br><br><br><br><br><br><p> - The artistic quality does not matter and neither the technique does. Your story is what matters. A blurry picture with a story behind it says more than a perfect shot with not much to say. - Try to build a narrative, more than simply showing pictures. For example, and please consider this as a suggestion and not the one and only way to build you story through your photos: </p>',
                        'right': '<p> Photo 1: here I am with my mother at the window of our home. We were in xxx area. My family has always lived there. My grandparents had a small garden to cultivate/my grandparents worked in a factory. As a kid I used to play in the street/square, building huts. At some point in time, we discovered the garden where we played was polluted/the factory was harming the air with xxx Photo 2: this picture is from the high school, we were 16 years old. Five of my classmates got sick and died. xxx Foto 3: from here you can see factory/landfill/trash/polluted air. I grew up by looking at this every single day. xxx Photo 4: this is a newspaper article/movie/book/leaflet which has been extremely important to me. I remember that when I red it xxx. Photo 5: this picture is from a rally/picket/march that I started to attend in xxx. My experience started for these reasons xxx. It is also fine to say that you never participated to a movement and explain why. </p> <p> </p> <p> How to send the photos - If the file is smaller than 25 MB (unlikely in the case of photos), you can send it to : ilenia@kth.se - If the file is bigger than 25 MB, go back to Chapter II. </p>'
                    }
                }
            },
            'chapter5': {
                'title': '<p> CAPITOLO V </p> <p> RACCONTA LA TUA </p> <p> AUTOBIOGRAFIA TOSSICA IN </p><p>QUALUNQUE ALTRO MODO</p><p>DESIDERI</p>',
                'page': {
                    '1': {
                        'left': '<p>A guerrilla narrative project cannot have rules. Everyone must feel free in telling a story. Unleash your creativity. We can only list few ideas, but what matters is: any way of telling your story is the right way!</p>',
                        'right': '<p>- Drawings<br>- Poems<br>- Medical records<br>- Collages<br>- Songs<br>- Letters</p>'
                    }
                }
            },
            'closing': {
                'page': {
                    '1': '<p><br> Once, the Canadian journalist Naomi Klein said that to impose an emergency, it is necessary to deprive a community of its stories.<br> To take back our stories is the first act of resistance to create an alternative path. </p>',
                    '2': 'Toxic Autobiographies',
                    '3': '<p> A KTH Environmental Humanities Laboratory project funded by The Seed Box a Mistra-Formas Environmental Humanities Collaboratory <a target="_blank" href="https://www.kth.se/en/abe/inst/philhist/historia/ehl/toxicbio">https://www.kth.se/en/abe/inst/philhist/historia/ehl/toxicbio</a> </p>'
                }
            }
        }
    };
});
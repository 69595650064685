define('kth/components/toxic-image', ['exports', 'ember'], function (exports, _ember) {

  var ToxicImage = _ember['default'].Component.extend({
    image: _ember['default'].computed('params.[]', function () {
      return this.get('params')[0];
    }),
    title: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 1 ? this.get('params')[1] : this.get('image') && this.get('image').title || '';
    }),
    /*  layout: Ember.computed('params.[]', function(){
        return this.get('params').length > 2 ? this.get('params')[2] : ENV.DEFAULT_BOOTSTRAP_LAYOUT;
      }),*/

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var toxicImage = _ember['default'].$(this.element).find('.toxic-image');
      var ref = toxicImage.attr('id');
      var paragraph = toxicImage.closest('.story-paragraph');
      var placerefContainer = paragraph.find('.placeref-container');
      var anchor = paragraph.find('a[href=' + ref + ']');
      var refType = anchor.attr('class');

      var element = this.element;

      anchor.attr('href', '#' + ref);
      switch (refType) {
        case 'placeholder':
          toxicImage.removeClass('toxic-element-hidden');
          anchor.after(element);
          break;
        case 'placeref':
          toxicImage.removeClass('toxic-element-hidden');
          placerefContainer.append(element);
          break;
        case 'modalLink':
          anchor.after(element);
          break;
      }
    },

    actions: {
      toggleZoom: function toggleZoom(event) {
        _ember['default'].$(event.currentTarget).closest('.zoomable').toggleClass('zoomed-in');
      }
    }
  });

  ToxicImage.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = ToxicImage;
});
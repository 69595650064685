define('kth/models/toxic-story', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    exports['default'] = _emberData['default'].Model.extend({
        // attributes
        title: attr('string'),
        description: attr('text'),
        status: attr('string'),
        nickname: attr('string'),

        created: attr('date'),
        modified: attr('date'),
        body: attr('text'),
        lang: attr('string'),
        created_by: attr('number'),
        modified_by: attr('number'),

        geo_tags: _emberData['default'].attr(),
        geoTag: _emberData['default'].attr(),
        relations: _emberData['default'].attr(),

        //    publish_start: attr('date'),
        //    publish_end: attr('date'),

        // fields displayed in lists
        listFields: ['id', 'title', 'created', 'modified']
    });
});
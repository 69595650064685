define('kth/router', ['exports', 'ember', 'kth/config/environment', 'kth/mixins/reset-scroll-position'], function (exports, _ember, _kthConfigEnvironment, _kthMixinsResetScrollPosition) {

    var Router = _ember['default'].Router.extend(_kthMixinsResetScrollPosition['default'], {
        location: _kthConfigEnvironment['default'].locationType,
        rootURL: _kthConfigEnvironment['default'].rootURL,
        i18n: _ember['default'].inject.service(),

        didTransition: function didTransition() {
            this._super.apply(this, arguments);
            var body = _ember['default'].$("html, body");
            var topPosition = 0;

            function getShrinkingOffset() {
                var windowSize = _ember['default'].$(window).width();
                if (windowSize > 1300) {
                    return 110;
                } else if (windowSize > 1200) {
                    return 70;
                } else if (windowSize > 992) {
                    return 70;
                } else {
                    return 40;
                }
            }

            if (_ember['default'].$('header').hasClass('smaller')) {
                topPosition = getShrinkingOffset() + 20;
            }

            _ember['default'].run.later(function () {
                body.stop().animate({ scrollTop: topPosition }, '50', 'swing');
            }, 25);
        }

    });

    Router.map(function () {
        this.route('about');
        this.route('intro');
        this.route('faq');
        this.route('guidelines');

        this.route('media');
        this.route('stories');
    });

    exports['default'] = Router;
});
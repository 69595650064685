define('kth/routes/guidelines', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            didTransition: function didTransition() {
                this._super();

                _ember['default'].$('.search-bar').css('visibility', 'hidden');
                return true;
            }
        }
    });
});
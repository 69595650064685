define('kth/components/parallax-image', ['exports', 'ember', 'kth/config/environment'], function (exports, _ember, _kthConfigEnvironment) {

  var ParallaxImage = _ember['default'].Component.extend({
    image: _ember['default'].computed('params.[]', function () {
      return this.get('params')[0];
    }),
    title: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 1 ? this.get('params')[1] : this.get('image') && this.get('image').title;
    }),
    layout: _ember['default'].computed('params.[]', function () {
      return this.get('params').length > 2 ? this.get('params')[2] : _kthConfigEnvironment['default'].DEFAULT_BOOTSTRAP_LAYOUT;
    })
  });

  ParallaxImage.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = ParallaxImage;
});
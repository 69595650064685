define('kth/components/language-select', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        i18n: _ember['default'].inject.service(),
        classNames: ['language-select'],
        defaultLang: 'en',

        willInsertElement: function willInsertElement() {
            this.set('i18n.locale', this.defaultLang);
        },

        locales: _ember['default'].computed('i18n.locale', 'i18n.locales', function () {
            var i18n = this.get('i18n');
            return this.get('i18n.locales').map(function (loc) {
                return { id: loc, text: i18n.t('language-select.language.' + loc) };
            });
        }),

        actions: {
            setLocale: function setLocale(lang) {
                this.set('i18n.locale', lang);
            }
        }
    });
});
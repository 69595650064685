define('kth/routes/media', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            didTransition: function didTransition() {
                this._super();

                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    _ember['default'].$('.search-bar').css('visibility', 'hidden');
                });
                return true;
            }
        }

    });
});
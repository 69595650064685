define('kth/initializers/leaflet-assets', ['exports', 'ember', 'ember-get-config'], function (exports, _ember, _emberGetConfig) {
  exports.initialize = initialize;

  /* global L */

  var isNone = _ember['default'].isNone;

  function initialize() /* container, application */{
    if (typeof FastBoot === 'undefined') {
      var prefix = '';

      if (!isNone(_emberGetConfig['default'].rootURL)) {
        prefix = _emberGetConfig['default'].rootURL;
      } else if (!isNone(_emberGetConfig['default'].baseURL)) {
        prefix = _emberGetConfig['default'].baseURL;
      }

      L.Icon.Default.imagePath = prefix + 'assets/images/';
    }
  }

  exports['default'] = {
    name: 'leaflet-assets',
    initialize: initialize
  };
});
define('kth/models/geo-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    exports['default'] = _emberData['default'].Model.extend({
        toxic_story: _emberData['default'].belongsTo('toxic-story'),

        title: attr('string'),
        address: attr('text'),
        latitude: attr('number'),
        longitude: attr('number'),
        object_id: attr('number'),
        gmaps_lookats: _emberData['default'].attr()
    });
});
define('kth/locales/it/translations', ['exports'], function (exports) {
    exports['default'] = {
        'language-select': {
            'language': {
                'it': 'ita',
                'en': 'eng'
            }
        },
        'guidelines': {
            'main': {
                'title': '<h1>Guerriglia</h1><h1>narrativa</h1>'
            },
            'intro': {
                'left': 'Abbiamo provato a fare un piccolo manuale di istruzioni per chi voglia partecipare al nostro progetto di\n                    guerriglia narrativa. Il manuale è diviso in 4 capitoli, uno per ciascuno dei diversi modi in cui\n                    puoi raccontare la tua storia. A questo abbiamo aggiunto un breve capitolo in cui citiamo soltanto modi diversi la cui\n                    realizzazione lasciamo alla fantasia di chi vorrà provarci.',
                'right': 'Buona guerriglia narrativa. E non dimenticate di agitarvi bene prima dell’uso di questo manuale...'
            },
            'chapter1': {
                'title': '<p>CAPITOLO I</p><p>SCRIVERE UNA AUTOBIOGRAFIA</p><p>TOSSICA</p>',
                'page': {
                    '1': {
                        'left': {
                            '1': '<p>Negli Stati Uniti alcuni studiosi hanno iniziato a parlare di “autobiografie tossiche”. \n                                Con questo termine indicano un genere di scrittura prodotta da persone in qualche modo affette da contaminazione.</p>',
                            '2': '<p>In Europa questo genere di scrittura è quasi assente e comunque invisibile, nel senso che questi testi \n                                non hanno avuto nessuna circolazione (magari autoprodotti o pubblicati con case editrici molto piccole). \n                                Lo scopo di questo progetto è di raccogliere i materiali già prodotti e soprattutto stimolare \n                                la scrittura di “autobiografie tossiche”.</p>'
                        },
                        'right': '<p>Qui di seguito pochi suggerimenti su come scrivere il proprio testo. - Puoi cominciare da notizie prettamente \n                            anagrafiche. Descriviti attraverso nome, età, provenienza, titolo di studio e professione. Racconta del tuo rapporto \n                            con il luogo dove vivi, anche partendo dalla tua infanzia, ma cerca di collegarlo alla storia tossica \n                            (ad esempio, se il tuo quartiere è cambiato, come, etc.). Racconta di esperienze politiche o di altro tipo \n                            di mobilitazione. - Racconta la tua storia di incontro con la contaminazione e l’ingiustizia ambientale. Può \n                            significare una storia di malattia per te o per qualcuno che conosci, o magari solo la paura di ammalarti tu o i \n                            tuoi cari. Oppure può significare l’impegno e la mobilitazione contro un progetto di costruzione di discarica/ \n                            inceneritore/impianti industriali, o una storia di lavoro e salute. O semplicemente può significare raccontare \n                            la quotidianità del rapporto con la contaminazione (i fuochi, la puzza, i rifiuti, gente intorno che si ammala, \n                            il paesaggio devastato etc.)</p>'
                    },
                    '2': {
                        'left': '<p>- Racconta la storia di come hai acquisto consapevolezza della contaminazione. Spesso siamo contaminati da \n                            cose che non vediamo, o anche se le vediamo ne siamo assuefatti. Nella tua storia come hai cominciato ad accorgerti \n                            della contaminazione? Cosa ti ha aperto gli occhi? Un amico, una lettura, un film, una malattia, un evento eccezionale, \n                            una manifestazione ecc.? Cosa ha significato imparare a vedere la contaminazione intorno a te e magari anche dentro di \n                            te? Hai cambiato abitudini? Hai cominciato a deprimerti? Hai cominciato a informarti? Oppure a mobilitarti? Hai cambiato \n                            quartiere o hai desiderato di farlo? Hai cambiato il tuo rapporto con l’ambiente? In alcuni casi l’incontro con la \n                            contaminazione puo’ essere stato ancora più drammatico, se ad esempio ha significato la malattia o addirittura la perdita \n                            di una persona cara. Sebbene possiamo solo immaginare quanto sia duro, vorremmo incoraggiarti a raccontare anche queste \n                            storie. Pensiamo che sia importante che non vadano perdute e che siano socializzate, che diventino, cioè, parte della \n                            memoria di tutto</p>',
                        'right': '<p>- Racconta la storia delle mobilitazioni contro la contaminazione. Anche in questo caso non ti soffermare su tutti \n                            gli aspetti, le date, i gruppi, ma invece racconta la tua storia. Hai partecipato a qualche mobilitazione? Quale? Quando? \n                            Cosa facevate e cosa hai fatto tu piu’ in particolare (non dare informazioni penalmente rilevanti, specie se decidi di firmare \n                            la tua storia)? Se non hai partecipato, spiega perché. Cosa ha significato questa mobilitazione nella tua vita? Hai trovato \n                            nuovi amici? Hai ritrovato la passione per la politica? E’ stata una delusione? Ti sei fatta troppi nemici? Potresti elencare \n                            le cose positive e magari quelle negative che vedi in queste lotte, nei movimenti.</p><p> - Cerca di mantenere un registro \n                            personale ed autobiografico nel raccontare del tuo quartiere o della tua città, racconta cosa hai vissuto e cosa hai provato \n                            in relazione ad eventi, momenti. - Non ti preoccupare della forma, della correttezza della lingua, o di cose del genere. Questo \n                            è un esperimento di Guerriglia narrativa, il che significa che non ci importa niente delle regole o delle formalità con cui in \n                            genere si pensa ad un testo scritto.</p>'
                    }
                }

            },
            'chapter2': {
                'title': '<p>CAPITOLO II</p><p> RACCONTA LA TUA AUTOBIOGRAFIA TOSSICA</p> <p> ATTRAVERSO UN VIDEO </p>',
                'page': {
                    '1': {
                        'left': '<p>Un altro modo per raccontare la tua storia può essere produrre un semplice video. Oggi siamo tutti \n                            ben attrezzati con telecamere, telefoni cellulari che sono meglio di macchine fotografiche, e computers con \n                            la telecamera incorporata. Magari non siamo capaci di fare un film da Oscar, ma possiamo registrare facilmente \n                            il nostro racconto. Qui di seguito alcuni suggerimenti tecnici, per quanto riguarda i contenuti vale quanto abbiamo \n                            spiegati nel capitolo I</p><br><br><br><br><br><br><p>- Prima di ogni cosa, se non ti senti a tuo agio con la \n                            tecnologia, considera che possiamo organizzare una ripresa con una persona che collabora al progetto. Devi solo \n                            scriverci a: ilenia[at]kth.se - Audio. Verifica che si senta bene. Prima di cominciare a registrare l’intera storia \n                            fai una prova e rivediti e riascoltati per essere sicuro che tutto funzioni.</p>',
                        'right': '<p> - Se c’è un rumore di sottofondo, come una sirena, un cane, etc. non pensare che devi cancellare e \n                            ricominciare tutto daccapo. Fermati per la durata del disturbo e poi ricominci da dove ti eri fermato. - Parla \n                            non troppo velocemente perché così sarà più facile fare i sottotitoli - Assicurati che ci sia abbastanza luce ed \n                            evita che la luce oscuri la telecamera (evita che sia diretta su di essa) </p> <p> </p> <p> Per inviarci il file \n                            video - Se il file è di massimo 25 MB (probabile nel caso di un video), inviacelo via mail a: ilenia[at]kth.se - \n                            Se il file è maggiore di 25 MB, non disperare, è molto semplice usare un sito per trasferire file di grandi dimensioni. \n                            Il sito https://wetransfer.com/ ti permette di poter aggiungere un file dal tuo computer, cliccando sull’icona blu \n                            del segno “+”, subito dopo devi inserire l’email alla quale vuoi mandare il file (ilenia[at]kth.se), poi, nella \n                            casella sotto, la tua email. A questo punto puoi scrivere un messaggio dove ti chiedo di inserire il tuo nome e cognome. \n                            Ultimo, ce l’abbiamo quasi fatta, clicca su “transfer” ed il file verrà caricato ed inviato a noi. Ti ringraziamo per \n                            aver seguito tutti questi passi!</p>'
                    }
                }
            },
            'chapter3': {
                'title': '<p> CAPITOLO III </p> <p> RACCONTA LA TUA </p> <p> AUTOBIOGRAFIA TOSSICA </p><p> ATTRAVERSO UNA </p><p>REGISTRAZIONE AUDIO </p>',
                'page': {
                    '1': {
                        'left': '<p> Registrarsi può essere una opzione più semplice, che evita le complicazioni di una ripresa video. Per questo \n                            basta ancora una volta un telefono cellulare (quasi tutti hanno la funzione registratore), oppure un registratore digitale, \n                            on un programma che si può usare dal computer o dall’Ipad, come Audacity. Per i contenuti, rimandiamo sempre al Capitolo I. </p>',
                        'right': '<p> - Se preferisci, una persona che collabora al progetto può venire a intervistarti. oppure possiamo \n                            organizzare una chiamata Skype. Contattaci a: ileniaokth.se - Parla lentamente - Regola la distanza dal microfono \n                            - Fai prima una prova (evita di registrare tutta la tua storia e scoprire alla fine che il suono non andava bene) \n                            - Se ci sono disturbi durante la registrazione, come sirene, cani, o altri rumori, interrompi per un attimo \n                            (basta restare in silenzio) e poi riprendi. Non c’è motivo di ricominciare tutto daccapo - Evita però di \n                            registrare in un ambiente con rumori continui di sottofondo (una televisione accesa, il traffico, un elettrodomestico \n                            rumoroso etc.) </p> <p> </p> <p> Per inviarci il file audio - Se il file è di massimo 25 MB (probabile nel caso \n                            di un audio), inviacelo via mail a: ileniaokth.se - Se il file è maggiore di 25 MB, non disperare, è molto \n                            semplice usare il sito https://wetransfer.com/ per trasferire file di grandi dimensioni. Per come fare in dettaglio, \n                            rimandiamo al capitolo II.</p>'
                    }
                }
            },
            'chapter4': {
                'title': '<p> CAPITOLO IV </p> <p> RACCONTA LA TUA </p> <p> AUTOBIOGRAFIA TOSSICA </p><p> ATTRAVERSO DELLE </p><p>FOTOGRAFIE</p>',
                'page': {
                    '1': {
                        'left': '<p>Nell’era digitale è facile produrre ed accumulare immagini. Se preferisci, puoi raccontare la tua storia \n                            attraverso delle immagini. Abbiamo detto almeno 5, perché altrimenti non è una storia ma solo una fotografia. Puoi \n                            utilizzare fotografie “d’archivio” nel senso che puoi usare foto che conservi per qualche motivo (su di te, la tua famiglia, \n                            il tuo quartiere, la contaminazione, le lotte, etc.), oppure puoi anche decidere di produrre delle fotografie per l’occasione. \n                            Per ogni foto scrivi una breve didascalia.</p><br><br><br><br><br><p>- Non è rilevante la qualità artistica o tecnica delle \n                            fotografie. Quello che è rilevante è la storia. Una foto sfocata che racconta una cosa è meglio di una foto perfetta che non \n                            dica niente. - Prova a costruire una narrativa e non solo mettere insieme delle foto. Ad esempio (questo è solo un esempio, \n                            non lo considerare come l’unico modo per costruire la tua storia attraverso le foto):</p>',
                        'right': '<p>Foto 1: In questa foto sono io con mia madre sul balcone di casa. Siamo nel quartiere di xxx. \n                            La mia famiglia ha sempre abitato lì. Mio nonno aveva un pezzo di terra che coltivava (oppure faceva \n                            l’operaio nella fabbrica, etc.). Da ragazzino giocavo a costruire capanne nella terra dietro casa. \n                            Poi abbiamo scoperto che la fabbrica inquinava con xxx Foto 2: questa è la fotografia della 3 liceo. \n                            5 dei miei compagni si sono ammalati di cancro. Xxx Foto 3: da qui si vede la fabbrica/discarica/rifiuti/fumi. \n                            Sono cresciuto vedendo tutti i giorni questa cosa. Xxx Foto 4: questo è un articolo di giornale/libro/volantino \n                            che è stato molto importante. Mi ricordo che quando lo ho letto xxx Foto 5: questa è la foto di una \n                            manifestazione/presidio/ assemblea che ho cominciato a frequentare nel xxx. La mia esperienza è stata xxx \n                            per questi motivi (oppure va anche bene raccontare che non hai mai partecipato alle manifestazioni e spiegare perché).\n                             </p> <br><br> <p> Per inviarci le foto - Se il file è di massimo 25 MB (probabile nel caso di foto), inviacelo \n                             via mail a: ilenia[at]kth.se - Se il file è maggiore di 25 MB, rimandiamo al capitolo II. </p>'
                    }
                }
            },
            'chapter5': {
                'title': '<p> CAPITOLO V </p> <p> RACCONTA LA TUA </p> <p> AUTOBIOGRAFIA TOSSICA IN </p><p>QUALUNQUE ALTRO MODO</p><p>DESIDERI</p>',
                'page': {
                    '1': {
                        'left': '<p>Un progetto di Guerriglia narrativa non può avere regole. Ognuno deve essere libero di raccontarsi come crede. \n                            Libera la tua creatività. Noi possiamo solo elencare qualche idea, ma quello che importa è il messaggio che vogliamo \n                            trasmettere: ogni modo di raccontarsi sarà benvenuto!</p>',
                        'right': '<p>- Disegni <br>- Poesie <br>- Referti clinici <br>- Collage <br>- Canzoni <br>- Raccolte di lettere'
                    }
                }
            },
            'closing': {
                'page': {
                    '1': '<p><br> Una volta la giornalista canadese Naomi Klein ha detto che per imporre uno stato di emergenza è necessario privare \n                        le comunità delle loro storie. Riprendersi la propria storia è il primo modo per resistere e costruire alternative. </p>',
                    '2': 'Autobiografie Tossiche',
                    '3': '<p> Un progetto dell\'Environmental Humanities Laboratory, KTH Stoccolma finanziato da The Seed Box a Mistra-Formas \n                        Environmental Humanities Collaboratory <a target="_blank" href="https://www.kth.se/en/abe/inst/philhist/historia/ehl/toxicbio">\n                        https://www.kth.se/en/abe/inst/philhist/historia/ehl/toxicbio</a></p>'
                }
            }
        }
    };
});